<template>
    <div class="esg">
        <div class="banner">
            <img src="@/assets/images/esg-banner.png" class="banner-img"/>
        </div>

        <div class="esg-info">
            <div class="info">
                <div class="title">Our Dedication</div>
                <div class="subtitle">We are dedicated to integrating environmental, social, and governance (ESG) considerations into all aspects of our investment activities. Every employee plays a crucial role in responsibly managing our investments. We proactively champion ESG initiatives throughout our entire portfolio.</div>
            </div>
        </div>

        <div class="esg-users">
            <div class="users">
                <div class="title">Our Dedication</div>
                <div class="subtitle">We are dedicated to integrating environmental, social, and governance (ESG) considerations into all aspects of our investment activities. Every employee plays a crucial role in responsibly managing our investments. We proactively champion ESG initiatives throughout our entire portfolio.</div>
                <img src="@/assets/images/logos.png"/>
            </div>
        </div>

        <div class="img-info">
            <img src="@/assets/images/esg-info.png"/>

            <div class="ab-panel">
                <div class="note">Diversity, Equity and Inclusion</div>
                <div class="msg">We seek to create a workplace where all our employees feel valued, included and empowered. Our goal is to foster an environment that is an incubator for great ideas and attracts the best talent. Creating a diverse and inclusive workforce that promotes connectivity and a sense of belonging across all of our businesses makes us better investors and a better firm.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.esg-info {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 393px;

    background-color: #F1F2F7;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 1224px;
        height: 193px;
    }

    .title {
        font-size: 48px;
        line-height: 31px;
        font-weight: 300;
        color: #050505;
    }

    .subtitle {
        font-size: 22px;
        line-height: 38px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 1px;
        color: #1E1E1E;
    }
}

.esg-users {
    display: flex;
    justify-content: center;

    width: 100%;
    height: 808px;
    padding-top: 100px;

    background-color: #FFFFFF;

    .users {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 1320px;
        height: 536px;

        .title {
            font-size: 48px;
            line-height: 31px;
            font-weight: 300;
            color: #050505;
            margin-bottom: 48px;
        }

        .subtitle {
            width: 1188px;
            font-size: 22px;
            line-height: 38px;
            font-weight: 300;
            text-align: center;
            letter-spacing: 1px;
            color: #1E1E1E;
            margin-bottom: 60px;
        }

        > img {
            width: 1188px;
        }
    }
}

.img-info {
    display: flex;

    position: relative;
    width: 100%;

    > img {
        width: 100%;
    }

    .ab-panel {
        position: absolute;
        top: -44px;
        right: 0;

        width: 691px;
        height: 497px;

        padding: 33px 0 0 56px;

        background: linear-gradient(294.92deg, #00457A 17.76%, #0A1F36 82.96%);

        .note {
            width: 428px;

            font-size: 48px;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 60px;

            margin-bottom: 30px;
        }

        .msg {
            width: 561px;

            font-size: 24px;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 38px;
        }
    }
}
</style>